import React from "react"
import { navigate} from "gatsby"

export const ImagePrimary = props => {
  return (
    <div onClick={() => navigate("/gallery_image", { state: { images: props.images } })} className="content">
      <div className="content-overlay" />
      <img className="content-image" src={props.img} />
      <div className="content-details fadeIn-bottom">
        <h3 className="content-title">{props.title}</h3>
        <p className="content-text">{props.description}</p>
      </div>
    </div>
  )
}
