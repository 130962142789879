import cosi0 from "../assets/images/cosi/cosi0s.jpg"
import cosi0lg from "../assets/images/cosi/cosi0.jpg"
import cosi2 from "../assets/images/cosi/cosi2s.jpg"
import cosi2lg from "../assets/images/cosi/cosi2.jpg"
import cosi3 from "../assets/images/cosi/cosi3s.jpg"
import cosi3lg from "../assets/images/cosi/cosi3.jpg"
import cosi4 from "../assets/images/cosi/cosi4s.jpg"
import cosi4lg from "../assets/images/cosi/cosi4.jpg"
import cosi5 from "../assets/images/cosi/cosi5s.jpg"
import cosi5lg from "../assets/images/cosi/cosi5.jpg"
import cosi6 from "../assets/images/cosi/cosi6s.jpg"
import cosi6lg from "../assets/images/cosi/cosi6.jpg"
import cosi7 from "../assets/images/cosi/cosi7s.jpg"
import cosi7lg from "../assets/images/cosi/cosi7.jpg"
import cosi8 from "../assets/images/cosi/cosi8s.jpg"
import cosi8lg from "../assets/images/cosi/cosi8.jpg"

import divas0 from "../assets/images/DIVAS/divas0s.jpg"
import divas0lg from "../assets/images/DIVAS/divas0.jpg"
import divas1 from "../assets/images/DIVAS/divas1s.jpg"
import divas1lg from "../assets/images/DIVAS/divas1.jpg"
import divas2 from "../assets/images/DIVAS/divas2s.jpg"
import divas2lg from "../assets/images/DIVAS/divas2.jpg"
import divas3 from "../assets/images/DIVAS/divas3s.jpg"
import divas3lg from "../assets/images/DIVAS/divas3.jpg"
import divas4 from "../assets/images/DIVAS/divas4s.jpg"
import divas4lg from "../assets/images/DIVAS/divas4.jpg"
import divas5 from "../assets/images/DIVAS/divas5s.jpg"
import divas5lg from "../assets/images/DIVAS/divas5.jpg"
import divas7 from "../assets/images/DIVAS/divas7s.jpg"
import divas7lg from "../assets/images/DIVAS/divas7.jpg"
import divas8 from "../assets/images/DIVAS/divas8s.jpg"
import divas8lg from "../assets/images/DIVAS/divas8.jpg"

import l5y0 from "../assets/images/L5Y/L5Y0s.jpg"
import l5y0lg from "../assets/images/L5Y/L5Y0.jpg"
import l5y1 from "../assets/images/L5Y/L5Y1s.jpg"
import l5y1lg from "../assets/images/L5Y/L5Y1.jpg"
import l5y2 from "../assets/images/L5Y/L5Y2s.jpg"
import l5y2lg from "../assets/images/L5Y/L5Y2.jpg"
import l5y3 from "../assets/images/L5Y/L5Y3s.jpg"
import l5y3lg from "../assets/images/L5Y/L5Y3.jpg"
import l5y4 from "../assets/images/L5Y/L5Y4s.jpg"
import l5y4lg from "../assets/images/L5Y/L5Y4.jpg"
import l5y5 from "../assets/images/L5Y/L5Y5s.jpg"
import l5y5lg from "../assets/images/L5Y/L5Y5.jpg"
import l5y6 from "../assets/images/L5Y/L5Y6s.jpg"
import l5y6lg from "../assets/images/L5Y/L5Y6.jpg"
import l5y7 from "../assets/images/L5Y/L5Y7s.jpg"
import l5y7lg from "../assets/images/L5Y/L5Y7.jpg"

import laboheme10 from "../assets/images/LaBoheme/laboheme10s.jpg"
import laboheme10lg from "../assets/images/LaBoheme/laboheme10.jpg"
import laboheme0 from "../assets/images/LaBoheme/laboheme0s.jpg"
import laboheme0lg from "../assets/images/LaBoheme/laboheme0.jpg"
import laboheme1 from "../assets/images/LaBoheme/laboheme1s.jpg"
import laboheme1lg from "../assets/images/LaBoheme/laboheme1.jpeg"
import laboheme2 from "../assets/images/LaBoheme/laboheme2s.jpg"
import laboheme2lg from "../assets/images/LaBoheme/laboheme2.jpg"
import laboheme3 from "../assets/images/LaBoheme/laboheme3s.jpg"
import laboheme3lg from "../assets/images/LaBoheme/laboheme3.jpg"
import laboheme4 from "../assets/images/LaBoheme/laboheme4s.jpg"
import laboheme4lg from "../assets/images/LaBoheme/laboheme4.jpg"
import laboheme6 from "../assets/images/LaBoheme/laboheme6s.jpg"
import laboheme6lg from "../assets/images/LaBoheme/laboheme6.jpg"
import laboheme7 from "../assets/images/LaBoheme/laboheme7s.jpg"
import laboheme7lg from "../assets/images/LaBoheme/laboheme7.jpg"
import laboheme8 from "../assets/images/LaBoheme/laboheme8s.jpg"
import laboheme8lg from "../assets/images/LaBoheme/laboheme8.jpg"
import laboheme9 from "../assets/images/LaBoheme/laboheme9s.jpg"
import laboheme9lg from "../assets/images/LaBoheme/laboheme9.jpg"

import littleWomen0 from "../assets/images/LittleWomen/littleWomen0s.jpg"
import littleWomen0lg from "../assets/images/LittleWomen/littleWomen0.jpg"
import littleWomen1 from "../assets/images/LittleWomen/littleWomen1s.jpg"
import littleWomen1lg from "../assets/images/LittleWomen/littleWomen1.jpg"
import littleWomen2 from "../assets/images/LittleWomen/littleWomen2s.jpg"
import littleWomen2lg from "../assets/images/LittleWomen/littleWomen2.jpg"
import littleWomen3 from "../assets/images/LittleWomen/littleWomen3s.jpg"
import littleWomen3lg from "../assets/images/LittleWomen/littleWomen3.jpg"
import littleWomen4 from "../assets/images/LittleWomen/littleWomen4s.jpg"
import littleWomen4lg from "../assets/images/LittleWomen/littleWomen4.jpg"
import littleWomen5 from "../assets/images/LittleWomen/littleWomen5s.jpg"
import littleWomen5lg from "../assets/images/LittleWomen/littleWomen5.jpg"
import littleWomen6 from "../assets/images/LittleWomen/littleWomen6s.jpg"
import littleWomen6lg from "../assets/images/LittleWomen/littleWomen6.jpg"
import littleWomen7 from "../assets/images/LittleWomen/littleWomen7s.jpg"
import littleWomen7lg from "../assets/images/LittleWomen/littleWomen7.jpg"
import littleWomen8 from "../assets/images/LittleWomen/littleWomen8s.jpg"
import littleWomen8lg from "../assets/images/LittleWomen/littleWomen8.jpg"
import littleWomen9 from "../assets/images/LittleWomen/littleWomen9s.jpg"
import littleWomen9lg from "../assets/images/LittleWomen/littleWomen9.jpg"
import littleWomen10 from "../assets/images/LittleWomen/littleWomen10s.jpg"
import littleWomen10lg from "../assets/images/LittleWomen/littleWomen10.jpg"

import N2N0 from "../assets/images/N2N/N2N0s.jpg"
import N2N0lg from "../assets/images/N2N/N2N0.jpg"
import N2N1 from "../assets/images/N2N/N2N1s.jpg"
import N2N1lg from "../assets/images/N2N/N2N1.jpg"
import N2N2 from "../assets/images/N2N/N2N2s.jpg"
import N2N2lg from "../assets/images/N2N/N2N2.jpg"
import N2N3 from "../assets/images/N2N/N2N3s.jpg"
import N2N3lg from "../assets/images/N2N/N2N3.jpg"
import N2N4 from "../assets/images/N2N/N2N4s.jpg"
import N2N4lg from "../assets/images/N2N/N2N4.jpg"
import N2N5 from "../assets/images/N2N/N2N5s.jpg"
import N2N5lg from "../assets/images/N2N/N2N5.jpg"
import N2N6 from "../assets/images/N2N/N2N6s.jpg"
import N2N6lg from "../assets/images/N2N/N2N6.jpg"
import N2N7 from "../assets/images/N2N/N2N7s.jpg"
import N2N7lg from "../assets/images/N2N/N2N7.jpg"
import N2N8 from "../assets/images/N2N/N2N8s.jpg"
import N2N8lg from "../assets/images/N2N/N2N8.jpg"
import N2N9 from "../assets/images/N2N/N2N9s.jpg"
import N2N9lg from "../assets/images/N2N/N2N9.jpg"

import or0 from "../assets/images/OR/OR0s.jpg"
import or0lg from "../assets/images/OR/OR0.jpg"
import or1 from "../assets/images/OR/OR1s.jpg"
import or1lg from "../assets/images/OR/OR1.jpg"
import or2 from "../assets/images/OR/OR2s.jpg"
import or2lg from "../assets/images/OR/OR2.jpg"
import or3 from "../assets/images/OR/OR3s.jpg"
import or3lg from "../assets/images/OR/OR3.jpg"
import or4 from "../assets/images/OR/OR4s.jpg"
import or4lg from "../assets/images/OR/OR4.jpg"
import or5 from "../assets/images/OR/OR5s.jpg"
import or5lg from "../assets/images/OR/OR5.jpg"
import or6 from "../assets/images/OR/OR6s.jpg"
import or6lg from "../assets/images/OR/OR6.jpg"

import pp0 from "../assets/images/PassionPlay/PP0s.jpg"
import pp0lg from "../assets/images/PassionPlay/PP0.jpg"
import pp1 from "../assets/images/PassionPlay/PP1s.jpg"
import pp1lg from "../assets/images/PassionPlay/PP1.jpg"
import pp3 from "../assets/images/PassionPlay/PP3s.jpg"
import pp3lg from "../assets/images/PassionPlay/PP3.jpg"
import pp4 from "../assets/images/PassionPlay/PP4s.jpg"
import pp4lg from "../assets/images/PassionPlay/PP4.jpg"
import pp5 from "../assets/images/PassionPlay/PP5s.jpg"
import pp5lg from "../assets/images/PassionPlay/PP5.jpg"
import pp6 from "../assets/images/PassionPlay/PP6s.jpg"
import pp6lg from "../assets/images/PassionPlay/PP6.jpg"
import pp7 from "../assets/images/PassionPlay/PP7s.jpg"
import pp7lg from "../assets/images/PassionPlay/PP7.jpg"
import pp8 from "../assets/images/PassionPlay/PP8s.jpg"
import pp8lg from "../assets/images/PassionPlay/PP8.jpg"
import pp9 from "../assets/images/PassionPlay/PP9s.jpg"
import pp9lg from "../assets/images/PassionPlay/PP9.jpg"

import vibPlay0 from "../assets/images/VibPlay/vib_play0s.jpg"
import vibPlay0lg from "../assets/images/VibPlay/vib_play0.jpg"
import vibPlay1 from "../assets/images/VibPlay/vib_play1s.jpg"
import vibPlay1lg from "../assets/images/VibPlay/vib_play1.jpg"
import vibPlay2 from "../assets/images/VibPlay/vib_play2s.jpg"
import vibPlay2lg from "../assets/images/VibPlay/vib_play2.jpg"
import vibPlay3 from "../assets/images/VibPlay/vib_play3s.jpg"
import vibPlay3lg from "../assets/images/VibPlay/vib_play3.jpg"
import vibPlay4 from "../assets/images/VibPlay/vib_play4s.jpg"
import vibPlay4lg from "../assets/images/VibPlay/vib_play4.jpg"
import vibPlay5 from "../assets/images/VibPlay/vib_play5s.jpg"
import vibPlay5lg from "../assets/images/VibPlay/vib_play5.jpg"
import vibPlay6 from "../assets/images/VibPlay/vib_play6s.jpg"
import vibPlay6lg from "../assets/images/VibPlay/vib_play6.jpg"
import vibPlay7 from "../assets/images/VibPlay/vib_play7s.jpg"
import vibPlay7lg from "../assets/images/VibPlay/vib_play7.jpg"
import vibPlay8 from "../assets/images/VibPlay/vib_play8s.jpg"
import vibPlay8lg from "../assets/images/VibPlay/vib_play8.jpg"
import vibPlay9 from "../assets/images/VibPlay/vib_play9s.jpg"
import vibPlay9lg from "../assets/images/VibPlay/vib_play9.jpg"
import vibPlay10 from "../assets/images/VibPlay/vib_play10s.jpg"
import vibPlay10lg from "../assets/images/VibPlay/vib_play10.jpg"

import burst1 from "../assets/images/Burst/Burst_1s.jpg"
import burst1g from "../assets/images/Burst/Burst_1.jpg"
import burst2 from "../assets/images/Burst/Burst_2s.jpg"
import burst2g from "../assets/images/Burst/Burst_2.jpg"
import burst3 from "../assets/images/Burst/Burst_3s.jpg"
import burst3g from "../assets/images/Burst/Burst_3.jpg"
import burst4 from "../assets/images/Burst/Burst_4s.jpg"
import burst4g from "../assets/images/Burst/Burst_4.jpg"
import burst5 from "../assets/images/Burst/Burst_5s.jpg"
import burst5g from "../assets/images/Burst/Burst_5.jpg"
import burst6 from "../assets/images/Burst/Burst_6s.jpg"
import burst6g from "../assets/images/Burst/Burst_6.jpg"
import burst7 from "../assets/images/Burst/Burst_7s.jpg"
import burst7g from "../assets/images/Burst/Burst_7.jpg"
import burst8 from "../assets/images/Burst/Burst_8s.jpg"
import burst8g from "../assets/images/Burst/Burst_8.jpg"

export const imageData = [
  {
    title: "Burst",
    description: "Chelsea Theatre Works ",
    img: burst1,
    images: [
      { img: burst1, lg: burst1g },
      { img: burst2, lg: burst2g },
      { img: burst3, lg: burst3g },
      { img: burst4, lg: burst4g },
      { img: burst5, lg: burst5g },
      { img: burst6, lg: burst6g },
      { img: burst7, lg: burst7g },
      { img: burst8, lg: burst8g },
    ],
  },
  {
    title: "La Bohème",
    description: "Long Island Lyric Opera",
    img: laboheme0,
    images: [
      { img: laboheme0, lg: laboheme0lg },
      { img: laboheme1, lg: laboheme1lg },
      { img: laboheme2, lg: laboheme2lg },
      { img: laboheme3, lg: laboheme3lg },
      { img: laboheme4, lg: laboheme4lg },
      { img: laboheme6, lg: laboheme6lg },
      { img: laboheme7, lg: laboheme7lg },
      { img: laboheme8, lg: laboheme8lg },
      { img: laboheme9, lg: laboheme9lg },
      { img: laboheme10, lg: laboheme10lg },
    ],
  },
  {
    title: "In the Next Room (or the vibrator play)",
    description: "Boston University MFA Thesis",
    img: vibPlay0,
    images: [
      { img: vibPlay0, lg: vibPlay0lg },
      { img: vibPlay1, lg: vibPlay1lg },
      { img: vibPlay2, lg: vibPlay2lg },
      { img: vibPlay3, lg: vibPlay3lg },
      { img: vibPlay4, lg: vibPlay4lg },
      { img: vibPlay5, lg: vibPlay5lg },
      { img: vibPlay6, lg: vibPlay6lg },
      { img: vibPlay7, lg: vibPlay7lg },
      { img: vibPlay8, lg: vibPlay8lg },
      { img: vibPlay9, lg: vibPlay9lg },
      { img: vibPlay10, lg: vibPlay10lg },
    ],
  },
  {
    title: "Little Women",
    description: "Opera North",
    img: littleWomen0,
    images: [
      { img: littleWomen0, lg: littleWomen0lg },
      { img: littleWomen1, lg: littleWomen1lg },
      { img: littleWomen2, lg: littleWomen2lg },
      { img: littleWomen3, lg: littleWomen3lg },
      { img: littleWomen4, lg: littleWomen4lg },
      { img: littleWomen5, lg: littleWomen5lg },
      { img: littleWomen6, lg: littleWomen6lg },
      { img: littleWomen7, lg: littleWomen7lg },
      { img: littleWomen8, lg: littleWomen8lg },
      { img: littleWomen9, lg: littleWomen9lg },
      { img: littleWomen10, lg: littleWomen10lg },
    ],
  },
  {
    title: "The Last 5 Years",
    description: "Arts After Hours",
    img: l5y0,
    images: [
      { img: l5y0, lg: l5y0lg },
      { img: l5y1, lg: l5y1lg },
      { img: l5y2, lg: l5y2lg },
      { img: l5y3, lg: l5y3lg },
      { img: l5y4, lg: l5y4lg },
      { img: l5y5, lg: l5y5lg },
      { img: l5y6, lg: l5y6lg },
      { img: l5y7, lg: l5y7lg },
    ],
  },
  {
    title: "La scuola degli amanti, ossia: Così Fan Tutt(i)",
    description: "Lowell House Opera at Harvard College",
    img: cosi0,
    images: [
      { img: cosi0, lg: cosi0lg },
      { img: cosi2, lg: cosi2lg },
      { img: cosi3, lg: cosi3lg },
      { img: cosi4, lg: cosi4lg },
      { img: cosi5, lg: cosi5lg },
      { img: cosi6, lg: cosi6lg },
      { img: cosi7, lg: cosi7lg },
      { img: cosi8, lg: cosi8lg },
    ],
  },
  {
    title: "Or,",
    description: "Simple Machine Theatre Company/ Maiden Phoenix",
    img: or0,
    images: [
      { img: or0, lg: or0lg },
      { img: or1, lg: or1lg },
      { img: or2, lg: or2lg },
      { img: or3, lg: or3lg },
      { img: or4, lg: or4lg },
      { img: or5, lg: or5lg },
      { img: or6, lg: or6lg },
    ],
  },
  {
    title: "DIVAS",
    description: "World Premiere, OperaHub",
    img: divas0,
    images: [
      { img: divas0, lg: divas0lg },
      { img: divas1, lg: divas1lg },
      { img: divas2, lg: divas2lg },
      { img: divas3, lg: divas3lg },
      { img: divas4, lg: divas4lg },
      { img: divas5, lg: divas5lg },
      { img: divas7, lg: divas7lg },
      { img: divas8, lg: divas8lg },
    ],
  },
  {
    title: "Next to Normal",
    description: "Arts After Hours",
    img: N2N0,
    images: [
      { img: N2N0, lg: N2N0lg },
      { img: N2N1, lg: N2N1lg },
      { img: N2N2, lg: N2N2lg },
      { img: N2N3, lg: N2N3lg },
      { img: N2N4, lg: N2N4lg },
      { img: N2N5, lg: N2N5lg },
      { img: N2N6, lg: N2N6lg },
      { img: N2N7, lg: N2N7lg },
      { img: N2N8, lg: N2N8lg },
      { img: N2N9, lg: N2N9lg },
    ],
  },
  {
    title: "Passion Play",
    description: "Boston University",
    img: pp0,
    images: [
      { img: pp0, lg: pp0lg },
      { img: pp1, lg: pp1lg },
      { img: pp3, lg: pp3lg },
      { img: pp4, lg: pp4lg },
      { img: pp5, lg: pp5lg },
      { img: pp6, lg: pp6lg },
      { img: pp7, lg: pp7lg },
      { img: pp8, lg: pp8lg },
      { img: pp9, lg: pp9lg },
    ],
  },
]
