import React from "react"
import MainLayout from "../components/layout/mainLayout"
import { imageData } from '../data/imageData';
import { ImagePrimary } from '../components/image';




const Media = props => {
  return (
    <>
      <MainLayout>
        <main className="Media">
          {imageData.map(data => (
            <ImagePrimary img={data.img} title={data.title} description={data.description} images={data.images}/>
          ))}
          
        </main>
      </MainLayout>
    </>
  )
}

export default Media
